import React from 'react';
import styled from 'styled-components';

import { withTheme } from '../components/ui';
import device from '../device';

import { isMobile } from '../utils/deviceDetector';
import Logo from './Logo';
import HeaderIcons from './HeaderIcons';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    position: ${props => (props.stickyHeader ? 'fixed' : 'static')};
    top: 0;
    width: 100%;
    background-color: ${props => props.theme.secondary};
    border-bottom: 1px solid ${props => props.theme.third};
    z-index: 99999;
`;

const MenuContainer = styled.div`
    flex: 2;

    @media ${device.tablet} {
        flex: 1.5;
        padding-right: 1rem;
    }

    @media ${device.laptop} {
        flex: 2;
        padding-right: 2rem;
    }
`;

const HeaderIconsContainer = styled.div`
    flex: 1;

    @media ${device.tablet} {
        flex: 2;
    }

    @media ${device.laptop} {
        flex: 1;
    }
`;

const LogoContainer = styled.div`
    flex: 2;
`;

const Header = withTheme(props => (
    <HeaderContainer {...props}>
        <MenuContainer>{isMobile() ? <MobileMenu /> : <DesktopMenu />}</MenuContainer>
        <HeaderIconsContainer>
            <HeaderIcons />
        </HeaderIconsContainer>
        <LogoContainer>
            <Logo />
        </LogoContainer>
    </HeaderContainer>
));

export default Header;
