import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import device from '../../../device';

const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const MenuContainer = styled.div`
    width: 35%;
    position: fixed;
    z-index: 1;
    top: 8%;
    left: 60%;
    background-color: white;
    overflow-x: hidden;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);

    @media ${device.tablet} {
        left: 55%;
    }
`;

const Menu = styled.div`
    position: relative;
    top: 25%;
    width: 100%;
    margin-right: 0.5rem;
`;

const MenuItem = styled.div`
    font-size: 1rem;
    padding: 0.5rem 0.2rem;

    @media ${device.tablet} {
        font-size: 1.7rem;
        padding: 1rem 0.4rem;
    }
`;

export default props => {
    return (
        <Overlay onClick={props.overlayClicked}>
            <MenuContainer>
                <Menu>
                    <Link to="/">
                        <MenuItem {...props} onClick={() => props.menuItemClicked(0)}>
                            ראשי
                        </MenuItem>
                    </Link>
                    {props.items &&
                        props.items.map((item, index) => (
                            <Link to={item.link}>
                                <MenuItem {...props} onClick={() => props.menuItemClicked(index + 1)}>
                                    {item.title}
                                </MenuItem>
                            </Link>
                        ))}
                </Menu>
            </MenuContainer>
        </Overlay>
    );
};
