export enum IconType {
    location = 'location',
    phone = 'phone',
    email = 'email',
    whatsapp = 'whatsapp',
    messenger = 'messenger',
    facebook = 'facebook',
    instagram = 'instagram',
    waze = 'waze',
}
