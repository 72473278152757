import { IconType } from '../../../types/icon';

const supportedTypes: { [key in IconType]: string } = {
    location: 'http://maps.google.com/?q=Studio MS Fitness',
    phone: 'tel:0525283287',
    email: 'mailto:michalsalame@gmail.com',
    whatsapp: 'https://api.whatsapp.com/send?phone=972525283287&text=הי, אשמח לקבל פרטים לגבי הסטודיו',
    messenger: 'http://m.me/Studio.MS.Fitness',
    facebook: 'https://www.facebook.com/Studio.MS.Fitness',
    instagram: 'https://www.instagram.com/studio_ms_fitness/',
    waze: 'https://www.waze.com/ul?ll=31.97414030%2C34.80951790&navigate=yes&zoom=16',
};

export default (type: IconType) => supportedTypes[type];
