import { withHandlers } from 'recompose';

export default withHandlers({
    sendMail: () => async (name: string, phone: string, source: string) => {
        await fetch('https://eopup365z0.execute-api.eu-central-1.amazonaws.com/prod/contactUs', {
            method: 'POST',
            headers: {
                'x-api-key': 'kNRrLTKAQb3yHOKv7Lgnq2TSQN5YCk6Z2gA7Hx4X',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
                phone,
                source,
            }),
        });
    },
});
