import React from 'react';
import { IconContext } from 'react-icons';
import styled from 'styled-components';

import device from '../../device';

import { withTheme } from '../ui';
import LinkContactDetail from './LinkContactDetail';
import { IconType } from '../../../types/icon';

const ContactDetailsContainer = styled.div`
    display: flex;
    justify-content: center;

    @media ${device.laptop} {
        justify-content: flex-start;
    }
`;

const IconLinkContactDetailsContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const IconLinkContactDetailContainer = styled.div`
    margin: auto 1rem;
`;

const TextLinkContactDetailContainer = styled.div`
    margin: 1rem auto;
`;

export default withTheme(({ theme }) => (
    <IconContext.Provider value={{ color: theme.primary }}>
        <div>
            <ContactDetailsContainer>
                <IconLinkContactDetailsContainer>
                    <IconLinkContactDetailContainer>
                        <LinkContactDetail type={IconType.whatsapp} size={30} />
                    </IconLinkContactDetailContainer>
                    <IconLinkContactDetailContainer>
                        <LinkContactDetail type={IconType.messenger} size={30} />
                    </IconLinkContactDetailContainer>
                    <IconLinkContactDetailContainer>
                        <LinkContactDetail type={IconType.facebook} size={30} />
                    </IconLinkContactDetailContainer>
                    <IconLinkContactDetailContainer>
                        <LinkContactDetail type={IconType.instagram} size={30} />
                    </IconLinkContactDetailContainer>
                    <IconLinkContactDetailContainer>
                        <LinkContactDetail type={IconType.waze} size={30} />
                    </IconLinkContactDetailContainer>
                </IconLinkContactDetailsContainer>
            </ContactDetailsContainer>
            <>
                <TextLinkContactDetailContainer>
                    <LinkContactDetail text={'052-5283287'} type={IconType.phone} />
                </TextLinkContactDetailContainer>
                <TextLinkContactDetailContainer>
                    <LinkContactDetail text={'michalsalame@gmail.com'} type={IconType.email} />
                </TextLinkContactDetailContainer>
                <TextLinkContactDetailContainer>
                    <LinkContactDetail
                        text={[
                            'רחוב ברשבסקי 7',
                            'אזור תעשיה ישן, "הרובע", ראשון לציון',
                            '- חניה פרטית ללקוחות הסטודיו',
                        ]}
                        type={IconType.location}
                    />
                </TextLinkContactDetailContainer>
            </>
        </div>
    </IconContext.Provider>
));
