import LocationIcon from './icons/LocationIcon';
import PhoneIcon from './icons/PhoneIcon';
import EmailIcon from './icons/EmailIcon';
import WhatsappIcon from './icons/WhatsappIcon';
import MessengerIcon from './icons/MessengerIcon';
import FacebookIcon from './icons/FacebookIcon';
import InstagramIcon from './icons/InstagramIcon';
import WazeIcon from './icons/WazeIcon';
import { IconType } from '../../../types/icon';
import { IconType as ReactIconsType } from 'react-icons';

const supportedIconTypes: { [key in IconType]: ReactIconsType } = {
    location: LocationIcon,
    phone: PhoneIcon,
    email: EmailIcon,
    whatsapp: WhatsappIcon,
    messenger: MessengerIcon,
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    waze: WazeIcon,
};

export default (type: IconType) => supportedIconTypes[type];
