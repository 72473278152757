import React from 'react';
import styled from 'styled-components';

import Map from '../components/Map';
import ContactUs from './ContactUs';
import ContactDetails from '../components/contactDetails';

import device from '../device';
import { isDesktop } from '../utils/deviceDetector';

export default props => (
    <footer>
        <>
            <ContactContainer>
                <ContactUsContainer>
                    <ContactUs {...props.contactUs} location={props.location} />
                </ContactUsContainer>

                <MapContainer>{isDesktop() && <Map />}</MapContainer>

                <ContactDetailsContainer>
                    <ContactDetails />
                </ContactDetailsContainer>
            </ContactContainer>

            <FooterRightsContainer>
                <FooterRightsText>© כל הזכויות שמורות לסטודיו MS Fitness</FooterRightsText>
                <FooterRightsText>ט.ל.ח</FooterRightsText>
            </FooterRightsContainer>
        </>
    </footer>
);

const ContactContainer = styled.div`
    @media ${device.laptop} {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
`;

const ContactUsContainer = styled.div`
    height: 15rem;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    margin: 0 auto 2rem auto;

    @media ${device.laptop} {
        flex: 0.7;
        padding-left: 1rem;
    }
`;

const MapContainer = styled.div`
    display: flex;
    flex: 1;
`;

const ContactDetailsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 0 auto 2rem auto;
`;

const FooterRightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;

    @media ${device.laptop} {
        margin-top: 2.5rem;
    }
`;

const FooterRightsText = styled.div`
    font-size: 0.5rem;
`;
