import React from 'react';
import styled from 'styled-components';

import device from '../../device';

import { withTheme } from '../ui';

const OuterTitleContainer = styled.span`
    display: table;
    background-color: ${({ third }: { third: string }) => third};
    border-radius: 4px;
    box-shadow: 0.5px 0.5px 4px 0 #505050;
    border: 1px solid rgb(213, 213, 213);
    margin: 0 auto;

    @media ${device.tablet} {
        border-radius: 8px;
        box-shadow: 1px 1px 8px 0 #505050;
        border: 2px solid rgb(213, 213, 213);
    }
`;

const InnerTitleContainer = styled.span`
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0rem 0.2rem;

    @media ${device.tablet} {
        padding: 0rem 0.5rem;
    }

    @media ${device.laptop} {
        padding: 0rem 2rem;
    }
`;

const Title = styled.em`
    color: ${({ primary }: { primary: string }) => primary};
    font-size: 1.5rem;
    line-height: 2rem;

    @media ${device.tablet} {
        font-size: 2.3rem;
        line-height: 3rem;
    }

    @media ${device.laptop} {
        font-size: 2rem;
        line-height: 4rem;
    }
`;

export default withTheme(
    ({ title, theme }: { title: string; theme: { primary: string; secondary: string; third: string } }) => (
        <OuterTitleContainer {...theme}>
            <InnerTitleContainer>
                <Title {...theme}>{title}</Title>
            </InnerTitleContainer>
        </OuterTitleContainer>
    )
);
