import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const DesktopMenuContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export default () => (
    <DesktopMenuContainer>
        <Link to="/">
            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>ראשי</span>
        </Link>
        <Link to="/#about">
            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>אודות</span>
        </Link>
        <Link to="/timetable">
            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>מערכת שעות</span>
        </Link>
        <Link to="/photos">
            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>תמונות</span>
        </Link>
        <Link to="/videos">
            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>סרטונים</span>
        </Link>
        <Link to="/#contactUs">
            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>צור קשר</span>
        </Link>
    </DesktopMenuContainer>
);
