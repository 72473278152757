import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import device from '../device';

import LogoImage from './LogoImage';

const LogoImageOuterContainer = styled.div`
    padding: 0.4rem 1.0875rem;
    display: flex;
    justify-content: flex-end;

    @media ${device.tablet} {
        padding-left: 3rem;
    }

    @media ${device.laptop} {
        padding-left: 2rem;
    }
`;

const LogoImageInnerContainer = styled.div`
    width: 40px;

    @media ${device.tablet} {
        width: 50px;
    }
`;

export default () => (
    <Link to="/">
        <LogoImageOuterContainer>
            <LogoImageInnerContainer>
                <LogoImage />
            </LogoImageInnerContainer>
        </LogoImageOuterContainer>
    </Link>
);
