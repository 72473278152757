import React from 'react';
import { IconContext } from 'react-icons';
import styled from 'styled-components';

import { isMobile, isiPad } from '../utils/deviceDetector';

import { withTheme } from './ui';
import LinkContactDetail from './contactDetails/LinkContactDetail';
import { IconType } from '../../types/icon';

const ContactDetailsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const IconLinkContactDetailContainer = styled.div`
    margin: auto 1rem;
`;

export default withTheme(({ theme }) => (
    <IconContext.Provider value={{ color: theme.primary }}>
        <ContactDetailsContainer>
            <IconLinkContactDetailContainer>
                <LinkContactDetail type={IconType.waze} size={isiPad() ? 65 : isMobile() ? 25 : 30} />
            </IconLinkContactDetailContainer>
            <IconLinkContactDetailContainer>
                <LinkContactDetail type={IconType.whatsapp} size={isiPad() ? 65 : isMobile() ? 25 : 30} />
            </IconLinkContactDetailContainer>
            <IconLinkContactDetailContainer>
                <LinkContactDetail type={IconType.phone} size={isiPad() ? 65 : isMobile() ? 25 : 30} />
            </IconLinkContactDetailContainer>
        </ContactDetailsContainer>
    </IconContext.Provider>
));
