import React from 'react';
import { createTheme, Theme } from './ui';

const theme = createTheme('#ef018d', '#f5f5f5', '#d5d5d5');

export default Component => props => (
    <Theme value={theme}>
        <Component {...props} />
    </Theme>
);
