import React from 'react';
import GoogleMapReact from 'google-map-react';

import Logo from './LogoImage';

const API_KEY = 'AIzaSyCo2NNCZPTZndacHRQ29XQEbOUpWJUIKps';

const mapProps = {
    center: {
        lat: 31.97417,
        lng: 34.809671,
    },
    zoom: 18,
};

const SmallLogo = () => (
    <div style={{ width: '20px' }}>
        <Logo />
    </div>
);

export default () => (
    <GoogleMapReact bootstrapURLKeys={{ key: API_KEY }} defaultCenter={mapProps.center} defaultZoom={mapProps.zoom}>
        <SmallLogo lat={31.97413} lng={34.809671} />
    </GoogleMapReact>
);
