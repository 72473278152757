/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';

import withTheme from './withTheme';
import Header from './Header';
import Footer from './Footer';

if (typeof window !== 'undefined') {
    // Make scroll behavior of internal links smooth
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', {
        updateURL: false,
    });
}

const Layout = ({ children, footer, stickyHeader, location }: { children: JSX.Element[] | JSX.Element }) => (
    <div>
        <div style={{ height: '4.2rem' }}>
            <Header stickyHeader={stickyHeader} />
        </div>
        <main>{children}</main>
        <Footer {...footer} location={location} />
    </div>
);

export default withTheme(Layout);
