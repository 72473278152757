import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const glowingOnDark = keyframes`
  0% {
    box-shadow: 0 0 3px #d81b60;
  }
  50% {
    box-shadow: 0 0 15px #f8bbd0;
  }
  100% {
    box-shadow: 0 0 3px #d81b60;
  }
`;

export const glowingOnLight = keyframes`
  0% {
    box-shadow: 0 0 3px #f8bbd0;
  }
  25% {
    box-shadow: 0 0 15px #d81b60;
  }
  50% {
    box-shadow: 0 0 25px #d81b60;
  }
  75% {
    box-shadow: 0 0 15px #ec407a;
  }
  100% {
    box-shadow: 0 0 3px #f8bbd0;
  }
`;

const animation = css`
    animation: ${({ glowingKeyframes }) => glowingKeyframes} 1500ms ease-in-out 0s infinite;
`;

const Box = styled.div`
    border-radius: ${({ overrideBorderRadius }) => overrideBorderRadius || '4px'};
    ${() => animation};
`;

export default (Component: JSX.Element) => ({
    glowingKeyframes,
    overrideBorderRadius,
    ...props
}: {
    overrideBorderRadius?: string;
    glowingKeyframes: object;
}) => (
    <Box overrideBorderRadius={overrideBorderRadius} glowingKeyframes={glowingKeyframes}>
        <Component {...props} />
    </Box>
);
