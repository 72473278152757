import React from 'react';
import { compose, withProps, withState } from 'recompose';

import { Menu } from './ui';

const MenuContainer = props => (
    <div>
        <button
            className={`hamburger hamburger--spin ${props.isOpen ? 'is-active' : undefined}`}
            type="button"
            onClick={() => props.setIsOpen(!props.isOpen)}
        >
            <span className="hamburger-box">
                <span className="hamburger-inner" />
            </span>
        </button>
        {props.isOpen ? (
            <Menu
                items={props.items}
                menuItemClicked={() => props.setIsOpen(false)}
                overlayClicked={() => props.setIsOpen(false)}
            />
        ) : null}
    </div>
);

export default compose(
    withProps({
        items: [
            {
                title: 'אודות',
                link: '/#about',
            },
            {
                title: 'מערכת שעות',
                link: '/timetable',
            },
            {
                title: 'תמונות',
                link: '/photos',
            },
            {
                title: 'סרטונים',
                link: '/videos',
            },
            {
                title: 'צור קשר',
                link: '/#contactUs',
            },
        ],
    }),
    withState('isOpen', 'setIsOpen', false)
)(MenuContainer);
