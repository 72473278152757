import React, { useState } from 'react';
import styled from 'styled-components';
import { MdSend } from 'react-icons/md';

import { getDeviceType } from '../utils/deviceDetector';

import withSendMail from './actions/withSendMail';
import { IconButton, FloatingTextInput, withTheme, withGlowingBox, glowingOnLight } from './ui';

import device from '../device';

const ContactUsContainer = props => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitError, setIsSubmitError] = useState(false);

    const contactUsProps = { ...props, setIsSubmit, setIsSubmitError };
    if (isSubmit) {
        return <div>קיבלנו את פנייתך ונחזור אלייך בהקדם!</div>;
    } else if (isSubmitError) {
        return <div>קרתה שגיאה. אנא נסי שוב במועד מאוחר יותר או צרי עמנו קשר באחד האמצעים האחרים. תודה.</div>;
    } else {
        return <ContactUs {...contactUsProps} source={props.source} location={props.location} />;
    }
};

const MainButtonComponent = (props: {
    children: JSX.Element | JSX.Element[];
    name: string;
    phone: string;
    source: string;
    location: any;
    isSubmitClicked: boolean;
    setIsSubmit: (boolean) => void;
    setIsSubmitError: (boolean) => void;
    setIsSubmitClicked: (boolean) => void;
    setIsNameError: (boolean) => void;
    setIsPhoneError: (boolean) => void;
    sendMail: (string, string, string) => void;
}) => (
    <IconButton
        disabled={props.isSubmitClicked}
        onClick={async () => {
            if (props.name.trim() === '') {
                props.setIsNameError(true);
            } else if (props.phone.trim() === '') {
                props.setIsPhoneError(true);
            } else {
                try {
                    props.setIsSubmitClicked(true);
                    await props.sendMail(
                        props.name,
                        props.phone,
                        `${getDeviceType()} + ${props.source || location.pathname}`
                    );
                    props.setIsSubmit(true);

                    fbq('track', 'CompleteRegistration', { content_name: props.source || location.pathname });
                } catch (e) {
                    props.setIsSubmitError(true);
                }
            }
        }}
    >
        {props.children}
    </IconButton>
);

const GlowingButton = withGlowingBox(MainButtonComponent);

const GlowingButtonContainer = styled.div`
    margin: 2rem 0;
    justify-content: center;
    align-items: center;
`;

const ContactUs = ({ sendMail, setIsSubmit, setIsSubmitError, theme, source, location, ...props }) => {
    const [name, setName] = useState('');
    const [isNameError, setIsNameError] = useState(false);
    const [phone, setPhone] = useState('');
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    return (
        <ContactUsForm id="contactUs">
            <TitleText>
                <FreeClassTitle>{props.title || 'אז את מגיעה?'}</FreeClassTitle>
            </TitleText>
            <FloatingTextInput
                onChange={e => {
                    if (e.target.value.trim() !== '') {
                        setIsNameError(false);
                    }
                    setName(e.target.value);
                }}
                value={name || ''}
                placeholder="שם"
                error={isNameError}
                errormessage="יש למלא שם"
                style={{ width: '80%', margin: '0 auto', padding: '2rem 0 0 0' }}
            />
            <FloatingTextInput
                onChange={e => {
                    if (e.target.value.trim() !== '') {
                        setIsPhoneError(false);
                    }
                    setPhone(e.target.value);
                }}
                value={phone || ''}
                placeholder="טלפון"
                error={isPhoneError}
                errormessage="יש למלא טלפון"
                style={{ width: '80%', margin: '0 auto', padding: '2rem 0 0 0' }}
            />
            <GlowingButtonContainer>
                <GlowingButton
                    glowingKeyframes={glowingOnLight}
                    {...{
                        name,
                        phone,
                        source,
                        location,
                        isSubmitClicked,
                        setIsSubmitClicked,
                        setIsNameError,
                        setIsPhoneError,
                        setIsSubmit,
                        setIsSubmitError,
                        sendMail,
                    }}
                >
                    <MdSend size={20} color={theme.second} />
                    <ContactMeText {...props}>צרו איתי קשר</ContactMeText>
                </GlowingButton>
            </GlowingButtonContainer>
        </ContactUsForm>
    );
};

const ContactUsForm = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
`;

const FreeClassTitle = styled.div`
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 900;
    text-align: center;

    @media ${device.tablet} {
        font-size: 2rem;
    }
`;

const TitleText = styled.div`
    display: flex;
    flex-direction: row;
`;

const ContactMeText = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    margin-right: 0.3rem;
    color: ${props => props.theme.second};
`;

export default withTheme(withSendMail(ContactUsContainer));
