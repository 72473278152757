import React from 'react';
import styled from 'styled-components';

import device from '../../device';

import iconProvider from './iconProvider';
import linkProvider from './linkProvider';
import { IconType } from '../../../types/icon';

type ContactDetail = {
    text?: string | string[];
    type: IconType;
    size?: number;
};

const ContactDetail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${device.laptop} {
        flex-direction: row;
        margin-top: 0.5rem;
    }
`;

const ExtraContactDetailText = styled.div`
    text-align: center;

    @media ${device.laptop} {
        text-align: right;
        margin-right: 1.5rem;
    }
`;

export default ({ text = '', type, size = 30 }: ContactDetail) => {
    const Icon = iconProvider(type);
    const link = linkProvider(type);
    const textMsg = Array.isArray(text) ? text[0] : text;
    return (
        <>
            <a id="linkContactDetail" href={link} target={'_blank'} onClick={() => fbq('track', 'Contact')}>
                <ContactDetail>
                    <Icon size={size} />
                    <span>{textMsg}</span>
                </ContactDetail>
                {Array.isArray(text) &&
                    text.length > 1 &&
                    text
                        .slice(1)
                        .map((text, index) => <ExtraContactDetailText key={index}>{text}</ExtraContactDetailText>)}
            </a>
        </>
    );
};
